.sceneContainer {
  border-top: 1px solid lightgrey;
  background: white;

  .scenes {
    width: calc(100vw - 180px);
    overflow: auto;
    white-space: nowrap;
  }
  .expandedScenes {
    @extend .scenes;

    width: calc(100vw - 55px);
  }
}
