.FeaturesSection {
  &__features {
    max-width: 900px;
    margin: 60px auto 0 auto;

    .row {
      // Reverse every other row
      &:nth-of-type(even) {
        flex-direction: row-reverse;
      }

      &:not(:last-of-type) {
        padding-bottom: 1.5rem;
        @media screen and (min-width: 769px) {
          padding-bottom: 2.5rem;
        }
      }
    }
  }

  &__image-container {
    max-width: 500px;
    margin: 30px auto;

    &:hover {
      > div {
        // opacity: 0;
        display: none;
        // transition: opacity 0.3s ease-in-out;
      }
    }
  }

  &__video-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
    background: RGBA(0, 0, 0, 0.5);

    width: 50px;
    height: 50px;
    cursor: pointer;
    outline: none;
    z-index: 10;

    border-radius: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__play-icon {
    color: white;
    width: 30px;
    height: 30px;
    margin-left: 5px;
    // fill: white;
    opacity: 1;
  }

  &__video {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
}
