.itemList {
  min-width: 200px;
}

.attachementItem {
  padding: 2px;
  font-size: small;
  display: flex;
  flex-direction: row;

  align-items: center;

  .itemContent {
    display: flex;
    flex-direction: row;

    align-items: center;
    width: 100%;

    .link {
      text-decoration: none;
      color: #3498ff;
      margin-left: 5px;
    }

    .attachementIcon {
      // width: 15px;
      // height: 15px;
      color: #989898;
    }

    .buttons {
      margin-left: auto;
    }

    .deleteIcon {
      @extend .attachementIcon;

      > svg {
        width: 15px;
        height: 15px;
      }

      padding: 2px;

      &:hover {
        color: #3498ff;
        cursor: pointer;
      }
    }
  }
}
