.attachementButton {
  .rs-btn-end-icon {
    margin-left: 0px !important;
  }
}

.modalSubtitle {
  font-size: 14px;
  color: #989898;
  margin-bottom: 10px;
}

.body {
  padding: 5px;
}

.input {
  margin-bottom: 10px;
}

.itemList {
  min-width: 200px;
}

.attachementItem {
  padding: 2px;
  font-size: small;
  display: flex;
  flex-direction: row;

  align-items: center;

  .itemContent {
    display: flex;
    flex-direction: row;

    align-items: center;
    width: 100%;

    .link {
      text-decoration: none;
      color: #3498ff;
      margin-left: 5px;
    }

    .attachementIcon {
      // width: 15px;
      // height: 15px;
      color: #989898;
    }

    .buttons {
      margin-left: auto;
    }

    .deleteIcon {
      @extend .attachementIcon;

      > svg {
        width: 15px;
        height: 15px;
      }

      padding: 2px;

      &:hover {
        color: #3498ff;
        cursor: pointer;
      }
    }
  }
}

.attachementDropdown {
  width: 20;
  margin-right: "10px";
  // padding-left: 15px;

  .popover {
    background: red;
    padding: 10px;
    min-width: 200px;
  }
}
