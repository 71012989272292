// .scenesContainer {
// border-radius: 5px;

// background-color: ghostwhite;

.filterBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  // background-color: rgb(218, 219, 221);

  border-bottom: 1px solid lightgray;

  .scenesStats {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: small;
    color: grey;
  }
}

.scenes {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;

  // border-radius: 5px;
  background-color: ghostwhite;
}
// }

.droppable {
  height: 130px;
  overflow-x: auto !important;
  display: flex;
  padding: 8px;
  width: 100%;
  // overflow-y: hidden !important;
}

.emptyMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-size: medium;
  color: grey;
}

.itemContainer {
  position: relative;

  .sceneIcon {
    width: 50px;
    height: 50px;
  }
}

.sceneType {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 10px;

  &:hover {
    background-color: lightgrey;
  }

  .sceneIcon {
    width: 50px;
    height: 50px;
  }
}

.scene {
  &:hover {
    .deleteBtn {
      display: block;
    }
    .editBtn {
      display: block;
    }
  }
  width: 150px;
  height: 100px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 5px;

  box-shadow: 0 0 0 1px rgb(0 0 0 / 10%);

  user-select: "none";
  padding: 8 * 2;
  margin: 0 8px 0 0;

  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

  &:active {
    cursor: pointer;
  }
}

.editBtn {
  display: none;
  background-color: lightgrey;
  position: absolute;
  right: 33px;
  top: 2px;
  width: 20px;
  height: 20px;
  //   border: 1px solid grey;
  border-radius: 50%;
  cursor: pointer;
  color: black;
}

.deleteBtn {
  display: none;
  background-color: lightgrey;
  position: absolute;
  right: 10px;
  top: 2px;
  width: 20px;
  height: 20px;
  //   border: 1px solid grey;
  border-radius: 50%;
  cursor: pointer;
  color: black;
}

.addBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 20px;

  z-index: 1;
  position: absolute;

  right: 0;
  top: 40%;
}
