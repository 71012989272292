.title {
  font-size: 1.4rem;
  font-weight: 600;
}

.body {
  padding: 20px 5px;
  font-size: medium;

  .text {
    margin-bottom: 20px;
    color: #000000;
    font-size: 1.1rem;
  }
}
