.emptyMsg {
  margin-top: 20px;
  font-size: small;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #989898;
  text-align: center;
}

.dragHandle {
  margin-right: 15px;
  margin-left: 30px;
}

.dragHandleIcon {
  width: 20px;
  height: 20px;
  color: #989898;
  cursor: grab;
}

.sectionDragHandle {
  margin-right: 5px;
  margin-left: 0px;
}

.itemName {
  font-size: small;
  // margin-left: 5px;
  cursor: pointer;

  // padding-left: 5px;
  transition: background 0.2s ease-in-out;
  border-radius: 5px;

  &:hover {
    background: #e5e5ea;
  }
}

.sectionHeader {
  // border-bottom: 1px solid #e5e5ea;
}

.section {
  padding-right: 15px;
}

.sectionName {
  font-size: 14px;
  font-weight: 600;
  margin-left: 5px;

  cursor: pointer;

  padding: 5px;
  transition: background 0.2s ease-in-out;
  border-radius: 5px;

  &:hover {
    background: #e5e5ea;
  }

  white-space: nowrap;
  overflow: hidden;
  // text-overflow: ellipsis;
}

.draggedOverSection {
  background: blue;
  color: red;
}

.draggingSection {
  background: #e4e4ff;
  color: #989898;
}
