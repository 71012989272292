.header {
  padding: 10px;
  border-bottom: 1px solid lightgrey;
  background: white;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.headline {
  color: #3c484e;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  margin-bottom: 0;

  cursor: pointer;

  padding: 5px;
  transition: background 0.2s ease-in-out;
  border-radius: 5px;

  &:hover {
    background: #e5e5ea;
  }
}
