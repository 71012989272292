.lecturePanel {
  background: #fff;
  position: fixed;
  margin: 10px;
  // margin-top: 58px;
  // max-height: 750px;
  transition: display 0.5s;
  height: 69vh;
  z-index: 1;
  width: inherit;
  padding: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
  color: #3c484e;

  .content {
    display: flex;
    flex-direction: column;

    height: inherit;
    justify-content: space-between;

    .header {
      // border-bottom: 1px solid #e5e5ea;
      // padding: 0 5px;
    }

    .listContainer {
      display: flex;
      flex-direction: column;

      overflow-y: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      padding: 5px;
      flex-grow: 2;
    }

    .listContainer::-webkit-scrollbar {
      display: none;
    }

    .list {
      overflow-y: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      // margin-bottom: auto;
      height: 100%;
    }

    .list::-webkit-scrollbar {
      display: none;
    }

    .footer {
      margin-bottom: 15px;
      font-size: 13px;
      color: #989898;

      .footerText {
        margin-left: 5px;
      }
    }
  }
}
