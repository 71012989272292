.popover {
  padding: 5px;
}

.draggable {
  padding: 0.5rem;
  margin: 0.5rem 0;
  border-radius: 0.5rem;
  background: white;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
  color: #3c484e;
}

.selected {
  @extend .draggable;
  box-shadow: 0 0 0 1px blue, 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.filtered {
  visibility: hidden;
  position: absolute;
  opacity: 0;

  transition: position 0.3s visibility 0.3s, opacity 0.3s ease-in-out;
}

.unfiltered {
  visibility: visible;
  position: inherit;
  opacity: 100;
}

.itemBody {
  position: relative;
  margin-top: 20px;
}

.itemFooter {
  border-top: 1px solid lightgray;
  color: #989898;
  // margin-right: 0.5rem;
  padding: 0.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.itemFooterWithAttachements {
  @extend .itemFooter;

  &:hover {
    // background-color: #f5f5f5;
    cursor: pointer;
  }
}
.label {
  font-size: small;
  margin-left: 5px;
  user-select: none;
}

.leftCorner {
  flex-grow: 1;
}

.rightCorner {
  //
}

.itemFooterExtension {
  padding-left: 15px;

  .itemList {
    list-style-type: none;
    padding: 0;
    margin: 0;

    .attachementItem {
      padding: 2px;
      font-size: medium;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .link {
        text-decoration: none;
        color: #3498ff;
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      &:hover {
        background: #f5f5f5;
        cursor: pointer;
      }

      .attachementIcon {
        margin-right: 5px;
        color: #989898;
      }
    }
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // align-items: flex-end;
  height: 40px;
  border-bottom: 1px solid lightgray;

  .sceneTypesLabel {
    margin-left: 5px;
  }

  .actionBar {
    display: flex;
    flex-direction: row;
    align-items: center;

    .itemIcon {
      margin-right: 5px;
    }

    .closeBtn {
      margin-left: 2px;
    }
  }
}

.typeHeader {
  display: flex;
  justify-items: center;
  align-items: center;
  color: #989898;

  .title {
    cursor: pointer;
    user-select: none;
    padding: 5px;
    transition: background 0.2s ease-in-out;
    border-radius: 5px;

    &:hover {
      background: #e5e5ea;
    }
  }

  .titleIcon {
    cursor: pointer;
    position: relative;
    top: -1px;
    margin-right: 5px;
  }

  .sceneIcon {
    margin-right: 0.2rem;
    width: 1.3rem;
    height: 1.3rem;
  }
}

.content {
  flex: 1 1 auto;
  padding: 0 1.5rem;
}

.draggableHanlde {
  flex: 0 0 auto;
  position: relative;
  width: 1rem;
  height: 1rem;
  top: -0.8rem;
  margin-right: 0.5rem;
  width: 1.5rem;
  cursor: grab;
  color: #989898;
}

.statsFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  position: relative;

  .textStats {
    font-size: small;
    color: #989898;
    margin-left: 5px;
    user-select: none;
  }

  .textSpace {
    font-size: small;
    color: #989898;
  }
}

.addScriptBtn {
  position: absolute;
  left: -45px;
  top: 15px;
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }
}

.hidden {
  display: none;
}
