.projectsView {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: ghostwhite;

  .header {
    margin-top: 80px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .headline {
      flex-grow: 1;
      color: #3c484e;
      text-align: left;
      font-family: "Roboto", sans-serif;
      font-size: 2rem;
      font-weight: 700;
      line-height: 1.5;
      letter-spacing: 0.00938em;
    }

    .headMenu {
      //
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex-grow: 1;

    .projectContainer {
      width: 90%;

      ul {
        .projectItem {
          list-style-type: none;
          padding: 5px 5px;

          display: flex;
          flex-direction: column;

          .caretIcon {
            font-size: 1.2rem;
          }

          .projectIcon {
            font-size: 1.1rem;
            margin-right: 5px;
            // color: #e4e4ff;
            color: #3c484e;
          }

          .caretBtn {
            margin-right: 5px;
          }

          .placeholder {
            margin-right: 40px;
          }

          .link {
            text-decoration: none;
            flex-grow: 1;
          }

          .projectDetail {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            align-items: center;

            &:hover {
              background: #e4e4ff;
            }

            border-radius: 5px;
            transition: background 0.1s ease-in;
          }

          .projectDigests {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            flex-grow: 1;
            // padding-left: 10px;
            padding: 0px 5px;
            margin-left: 40px;

            &:hover {
              background: #e4e4ff;
            }

            border-radius: 5px;
            transition: background 0.1s ease-in;

            .digestItem {
              display: flex;
              flex-direction: row;
              align-items: center;

              justify-content: flex-start;

              padding: 5px 0px;

              .digestIcon {
                // font-size: 1.2rem;
                margin-right: 5px;
              }

              .digestLabel {
                font-size: 1rem;
                // color: #3c484e;
                // font-family: "Roboto", sans-serif;
                // font-weight: 400;
                // letter-spacing: 0.00938em;
                margin: 0px;
              }
            }
          }
        }
        .noProjects {
          padding: 100px 5px;

          display: flex;
          flex-direction: row;
          align-items: center;

          .label {
            text-align: center;
            width: 100%;
            font-size: 1.2rem;
            color: lightgray;
            font-family: "Roboto", sans-serif;
            font-weight: 400;

            letter-spacing: 0.00938em;
            margin: 0px;
          }
        }
      }
    }
  }
}
