.scriptContainer {
  overflow: auto;

  .script {
    padding: 40px;

    //
    .scriptBlock {
      height: 200px;
      background: white;
      border: 1px solid lightgrey;
    }
  }
}
