.layout {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  // background-color: #3c484e;
  color: #fff;

  // overflow: hidden;

  .sidebarContainer {
    border-right: 1px solid lightgrey;
    display: flex;
    flex-direction: column;
  }

  .headerStyles {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;
    padding-top: 15px;
    margin-bottom: 11px;
    font-size: 1.5rem;
    font-family: "Outfit", sans-serif;
    font-weight: 600;
    // color: black;
    color: #663399;
    // color: rgb(44, 44, 182);
    white-space: "nowrap";

    .brandName {
      margin-left: 5px;
    }
  }
}

.body {
  a {
    text-decoration: none;
  }
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}
