.HeroSection {
  &__image-container {
    max-width: 570px;
  }

  &__image {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
      rgba(0, 0, 0, 0.22) 0px 10px 10px;
  }
}
