.popover {
  padding: 5px;
}

.draggable {
  padding: 0.5rem;
  margin: 0.5rem 0;
  border-radius: 0.5rem;
  background: white;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
  color: #3c484e;
}

.itemBody {
  margin-top: 20px;
  display: flex;
}

.itemFooter {
  border-top: 1px solid lightgray;
  color: #989898;
  // margin-right: 0.5rem;
  padding: 0.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.itemFooterWithAttachements {
  @extend .itemFooter;

  &:hover {
    // background-color: #f5f5f5;
    cursor: pointer;
  }
}
.label {
  font-size: small;
  margin-left: 5px;
  user-select: none;
}

.leftCorner {
  flex-grow: 1;
}

.rightCorner {
  //
}

.itemFooterExtension {
  padding-left: 15px;

  .itemList {
    list-style-type: none;
    padding: 0;
    margin: 0;

    .attachementItem {
      padding: 2px;
      font-size: medium;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .link {
        text-decoration: none;
        color: #3498ff;
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      &:hover {
        background: #f5f5f5;
        cursor: pointer;
      }

      .attachementIcon {
        margin-right: 5px;
        color: #989898;
      }
    }
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // align-items: flex-end;
  height: 40px;
  border-bottom: 1px solid lightgray;

  .sceneTypesLabel {
    margin-left: 5px;
  }

  .actionBar {
    display: flex;
    flex-direction: row;
    align-items: center;

    .itemIcon {
      margin-right: 5px;
    }

    .closeBtn {
      margin-left: 2px;
    }
  }
}

.typeHeader {
  display: flex;
  justify-items: center;
  align-items: center;
  color: #989898;

  .title {
    cursor: pointer;
    user-select: none;
  }

  .titleIcon {
    cursor: pointer;
    position: relative;
    top: -1px;
    margin-right: 5px;
  }

  .sceneIcon {
    margin-right: 0.2rem;
    width: 1.3rem;
    height: 1.3rem;
  }
}

.content {
  flex: 1 1 auto;
  padding-right: 0.5rem;
}

.draggableHanlde {
  flex: 0 0 auto;
  position: relative;
  width: 1rem;
  height: 1rem;
  top: -0.8rem;
  margin-right: 0.5rem;
  width: 1.5rem;
  cursor: grab;
  color: #989898;
}
