.menuBar {
  position: fixed;
  background-color: antiquewhite;
  z-index: 1;
  width: 800px;
}

.editor {
  margin-top: 50px;
  // padding: 20px;

  display: flex;
  justify-content: center;

  p {
    font-size: 16px;
  }

  > div {
    width: 75%;
    max-width: 75vw;
  }
}

.iconWrapper {
  text-align: center;
  padding-bottom: 20px;
}
