.headlineInput {
  max-width: 300px;
  color: #3c484e;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}
